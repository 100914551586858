import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import QICards from 'components/Indoor_Cameras/IN-8003_HD/Quick_Installation/QICards';
import NavButtons from 'components/Indoor_Cameras/IN-8003_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8003 Full HD Installation",
  "path": "/Indoor_Cameras/IN-8003_HD/Quick_Installation/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-8003 Full HD Indoor IP Camera Quick Installation",
  "image": "./P_SearchThumb_IN-8003HD_Quick_Installation.png",
  "social": "/images/Search/P_SearchThumb_IN-8003HD_Quick_Installation.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8003HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-8003 Full HD Manual Installation' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8003 HD is the first INSTAR camera with the new 1080p chipset.' image='/images/Search/P_SearchThumb_IN-8003HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-8003HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8003_HD/Schnell_Installation/' locationFR='/fr/Indoor_Cameras/IN-8003_HD/Quick_Installation/' crumbLabel="Installation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "quick-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#quick-installation",
        "aria-label": "quick installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick Installation`}</h2>
    <QICards mdxType="QICards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`First, please use the metal tool to carefully open the metal ring that is fixed on top of the glass through the holes. Then you can use the rubber tool to lift up the glass front as shown in below instruction.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      